<template>
  <div class="login-form">
    <div class="top-section">
      <div class="logo">
        <img src="~@/assets/images/logo/light.png" alt="" />
      </div>
      <!-- <div class="hint-section">
        <div class="hint-main">
          Login on <span class="eggsbook">ESB</span> <span class="effect"> Fx</span>
        </div>
        <div class="hint-sub">Sign in to continue to ESB FX.</div>
      </div> -->
    </div>
    <div class="form-section mt-5 mb-4">
      <b-form @submit.prevent="onLogin">
        <div class="auth-title">
          <h3>Register</h3>
          <p>Please connect your MetaMask wallet or Email to register</p>
        </div>
        <div class="form-group">
          <b-button
            variant="none"
            type="button"
            @click="onLoginMetaMask"
            class="btn-auth d-flex align-items-center justify-content-center"
          >
            Login by MetaMask
          </b-button>
          <b-button
            variant="none"
            type="button"
            @click="loginEmailActive"
            class="btn-auth d-flex align-items-center justify-content-center"
          >
            Login by Email
          </b-button>
        </div>
        <div v-if="loginEmail" class="mt-4">
          <div class="form-group">
            <label for="email"> Email</label>
            <b-input-group class="">
              <template #prepend>
                <b-input-group-text>
                  <i class="fas fa-envelope"></i>
                </b-input-group-text>
              </template>
              <b-form-input
                type="email"
                required
                id="email"
                v-model.trim="login.email"
                placeholder="Enter Your Email"
              ></b-form-input>
            </b-input-group>
          </div>
          <div class="form-group">
            <label for="password"> Password</label>
            <b-input-group class="">
              <template #prepend>
                <b-input-group-text>
                  <i class="fad fa-key"></i>
                </b-input-group-text>
              </template>
              <b-form-input
                type="password"
                required
                id="password"
                v-model.trim="login.password"
                placeholder="Enter Your Password"
              ></b-form-input>
            </b-input-group>
          </div>
          <div class="form-group" v-if="showauth">
            <label for="g2a">User 2 Factor Authentication</label>
            <b-input-group class="">
              <template #prepend>
                <b-input-group-text>
                  <i class="far fa-shield-alt"></i>
                </b-input-group-text>
              </template>
              <b-form-input
                type="text"
                required
                id="g2a"
                v-model.trim="login.authCode"
                placeholder="Enter Your Code"
              ></b-form-input>
            </b-input-group>
          </div>
          <div class="form-group">
            <div class="forgot">
              <i class="fad fa-lock-alt"></i>
              <span>
                <router-link :to="{ name: 'Forgot' }">Forgot your password ?</router-link>
              </span>
            </div>
          </div>
          <div class="form-group">
            <b-button
              variant="none"
              type="submit"
              class="btn-success mx-auto min-125 d-flex align-items-center justify-content-center"
            >
              Login
              <i class="fas fa-sign-in-alt ml-1"></i>
            </b-button>
            <!-- <b-button
              v-else
              variant="none"
              type="button"
              class="btn-auth d-flex align-items-center justify-content-center"
            >
              The System Is Under Maintenance
            </b-button> -->
          </div>
        </div>
      </b-form>
    </div>
    <div class="other-section">
      <!-- <h6 class="my-4">Or Login With</h6> -->
      <div class="m-3 text-center p-3 redirect-section">
        <h5 class="">Don't have an account ?</h5>
        <router-link :to="{ name: 'Register' }"
          >Free Register <i class="fas fa-sign-in-alt ml-1"></i
        ></router-link>
      </div>
    </div>
    <div class="other-section">
      <div class="text-center redirect-section">
        <h5 class="">Resend your email if you don't receive the active email when you sign up.</h5>
        <a @click="showFormResendMail = !showFormResendMail">Resend Mail </a>
      </div>
    </div>
    <b-form v-if="showFormResendMail">
      <div class="form-group text-left">
        <label for="email" class="text-white"> Email</label>
        <b-input-group class="">
          <template #prepend>
            <b-input-group-text>
              <i class="fas fa-envelope"></i>
            </b-input-group-text>
          </template>
          <b-form-input
            type="email"
            required
            id="email"
            v-model.trim="emailResend"
            placeholder="Enter Your Email"
          ></b-form-input>
        </b-input-group>
      </div>
      <div class="form-group">
        <b-button
          variant="none"
          type="button"
          @click="onResendMail"
          class="btn-auth d-flex align-items-center justify-content-center"
        >
          Resend
        </b-button>
      </div>
    </b-form>
    <!-- Modal notification -->
    <b-modal id="modal-1" title="PROGRAM" centered hide-footer hide-header>
      <div class="close" @click="$bvModal.hide('modal-1')">x</div>
      <VueSlickCarousel v-bind="settingsBannerProgame">
        <div
          class="carousel-item-home"
          v-for="banner in bannerList"
          :key="`${banner.image}-${banner.id}`"
        >
          <a>
            <img :src="banner.image" width="100%" alt="" />
          </a>
        </div>
      </VueSlickCarousel>
    </b-modal>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import Web3 from 'web3';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

const axios = require('@/store/config/axios.config').default;

export default {
  // components: { GetPassword, VueMetamask },
  components: { VueSlickCarousel },
  data() {
    return {
      login: {
        email: null,
        password: null,
        authCode: null,
        token: null,
      },
      showauth: false,
      loginEmail: false,
      showFormResendMail: false,
      emailResend: '',
      settingsBannerProgame: {
        dots: false,
        dotsClass: 'slick-dots custom-dot-class',
        edgeFriction: 0.35,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        adaptiveHeight: true,
      },
      bannerList: [],
    };
  },
  computed: {
    ...mapGetters({
      // Maintainers: 'core/Maintainers',
    }),
  },
  created() {
    if (this.$route.query.s && this.$route.query.m) {
      // eslint-disable-next-line eqeqeq
      if (this.$route.query.s == '1') {
        this.$toastr.s(this.$route.query.m);
      }
      // eslint-disable-next-line eqeqeq
      if (this.$route.query.s == '0') {
        this.$toastr.w(this.$route.query.m);
      }
      // eslint-disable-next-line eqeqeq
      if (this.$route.query.s == '-1') {
        this.$toastr.e(this.$route.query.m);
      }
    }
    this.unsubscribe = this.$store.subscribe((mutation) => {
      // eslint-disable-next-line default-case
      switch (mutation.type) {
        case 'auth/REQUIRE_AUTH':
          this.showauth = true;
          this.outLoad();
          break;
        case 'user/RESEND_MAIL_SUCCESS':
          this.emailResend = '';
          this.showFormResendMail = false;
          break;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    ...mapMutations(['onLoad', 'outLoad']),
    async onLogin() {
      if (this.Maintainers === 1) return;
      const token = await this.genarateCaptChaV3('register');
      if (!token) {
        this.$toastr.e('Too Fast! Please Wating Recaptcha.', 'Withdraw Failed');
      }
      this.login.token = token;
      this.$store.dispatch('auth/req_postLogin', this.login);
    },
    async onLoginMetaMask() {
      try {
        const checkWallet = await this.checkWallet();
        if (!checkWallet) {
          return;
        }
        const address = Web3.utils.toChecksumAddress(await this.$store.state.wallet.addr);
        const data = await axios.post('api/v1/address/login', {
          address,
        });
        if (data && data.message) {
          const signature = data.message;
          const txData = await window.ethereum.request({
            method: 'personal_sign',
            params: [address, signature],
          });
          // eslint-disable-next-line no-shadow
          const data2 = await axios.post('api/v1/address/login', {
            address,
            signature: txData,
            message: data.message,
          });
          if (data2 && data2.token) {
            this.$store.commit('auth/LOGIN_METAMASK_SUCCESS', data2);
          }
        }
      } catch (e) {
        this.$toastr.e(e.message, 'Oops!');
        this.outLoad();
      }
    },
    loginEmailActive() {
      if (this.loginEmail === false) {
        this.loginEmail = true;
      } else {
        this.loginEmail = false;
      }
    },
    onResendMail() {
      this.$store.dispatch('user/req_resendMail', { email: this.emailResend });
    },
    async getBanner() {
      const banner = await axios.get('/api/v1/notification/get-notification', { params: { type: 2 } });
      // console.log(banner);
      if (banner.length > 0) {
        this.bannerList = banner;
        this.$bvModal.show('modal-1');
      }
    },
  },
  mounted() {
    this.getBanner();
  },
};
</script>
<style lang="scss">
#modal-1 {
  .modal-dialog {
    max-width: 500px;
    width: 100%;
    .modal-content {
      background-color: #0f233b;
      border-radius: 1rem;
      // .modal-header {
      //   color: #4effff;
      //   text-transform: uppercase;
      //   font-family: 'HHBI';
      //   .close {
      //     color: #4effff;
      //   }
      // }
      .modal-body {
        padding: 0;
        .close {
          position: absolute;
          top: 5px;
          right: 10px;
          z-index: 1;
          color: #4effff;
          text-transform: uppercase;
          font-family: 'HHBI';
          cursor: pointer;
        }
        .carousel-item-home {
          img {
            width: 100%;
            height: auto;
            border-radius: 1rem;
          }
        }
      }
    }
  }
  .slick-arrow {
    z-index: 30;
    width: max-content;
    height: max-content;

    &.slick-next {
      right: 5%;
      &:before {
        content: '';
        background: url('~@/assets/images/icons/next.png');
        background-size: 100%100%;
        background-repeat: no-repeat;
        height: 40px;
        width: 25px;
        display: block;
      }
    }
    &.slick-prev {
      left: 5%;
      &:before {
        content: '';
        background: url('~@/assets/images/icons/prev.png');
        background-size: 100%100%;
        background-repeat: no-repeat;
        height: 40px;
        width: 25px;
        display: block;
      }
    }
    @media (min-width: 1366px) {
      &.slick-next {
        right: 0%;
      }
      &.slick-prev {
        left: 0%;
      }
    }
    @media (max-width: 425px) {
      &.slick-next,
      &.slick-prev {
        &:before {
          height: 36px;
          width: 25px;
        }
      }
      &.slick-next {
        right: 1%;
      }
      &.slick-prev {
        left: 1%;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.min-125 {
  min-width: 125px;
}
.auth-title {
  text-align: center;
  h3 {
    color: #fff;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
  }
  p {
    color: #fff;
  }
}
.login-form {
  min-width: 274px;
  max-width: calc(100% - 50px);
  span {
    &.effect {
      background: #009750;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 900;
    }
    &.eggsbook {
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 900;
      color: #5d5d5d;
    }
  }
  .top-section {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 35px auto 20px;
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
      flex: 0 0 100px;
      min-width: 100px;
      img {
        height: auto;
        width: 250px;
        margin-bottom: 5px;
      }
    }
    .hint-section {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      color: #212529;
      .hint-main {
        font-weight: 900;
        font-size: clamp(20px, 1.5vw, 22px);
        line-height: 120%;
        margin-bottom: 5px;
      }
      .hint-sub {
        font-size: clamp(14px, 1vw, 16px);
        color: #98a6ad;
        line-height: 115%;
        font-weight: 600;
      }
      span {
        &.effect {
          background: #009750;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: clamp(20px, 1.5vw, 22px);
          text-transform: uppercase;
          font-weight: 900;
          line-height: 120%;
        }
        &.eggsbook {
          font-size: clamp(18px, 1.5vw, 20px);
          text-transform: uppercase;
          font-weight: 900;
          color: #5d5d5d;
          line-height: 120%;
        }
      }
    }
  }
  .form-section {
    max-width: 100%;
    margin: 0 auto;
    label {
      font-weight: 400;
      color: #fff;
      font-size: 16px;
      margin-bottom: 5px;
    }
    .input-group-text {
      background: transparent;
      padding: 0;
      border: 1px solid #fff;
      width: 37px;
      height: 37px;
      display: flex;
      color: #fff;
      justify-content: center;
      align-items: center;
    }
    select,
    input {
      border: 1px solid #e9ecef;
      height: 37px;
      font-size: clamp(15px, 1vw, 16.2px);
      font-weight: 600;
      padding: 0.375rem 0.75rem;
      border-radius: 5px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      max-width: calc(100% - 45px);
      &:focus-visible,
      &:focus {
        box-shadow: 0 0 0 1px rgba(56, 169, 73, 45%);
        outline: none;
      }
      &::placeholder {
        font-weight: 500;
        font-size: clamp(13px, 1vw, 15px);
      }
    }
    .form-group {
      .forgot {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 20px;
        i,
        svg {
          margin-right: 5px;
          color: #98a6ad;
          font-size: clamp(14px, 1.5vw, 16px);
        }
        span a {
          color: #fff;
          font-weight: 600;
          font-size: clamp(14px, 1.5vw, 16px);
        }
      }
    }
  }
  button {
    margin-top: 20px;
    &.btn-auth {
      border: 1px solid #008cff;
      box-shadow: 0 2px 6px 0 #008cff;
      letter-spacing: 0.05rem;
      position: relative;
      background: #008cff;
      color: #fff !important;
      min-width: 130px;
      width: 100%;
      height: 40px;
      overflow: hidden;
      transition: 0.3s ease-in-out;
      border-radius: 0.3rem;
      z-index: 1;
      &:hover,
      &:focus,
      &:focus-visible {
        box-shadow: 0 5px 18px rgba(0, 0, 0, 0.2), 0 10px 12px rgba(0, 0, 0, 0.2);
      }
    }
  }
  .other-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h6 {
      color: #5d5b6f;
      font-size: clamp(15px, 1vw, 16px);
      font-weight: 600;
      margin: auto;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &:before {
        display: inline-block;
        margin: 0 14px 4px 0;
        height: 2px;
        content: ' ';
        text-shadow: none;
        background-color: #e9ecef;
        width: 25%;
      }
      &:after {
        display: inline-block;
        margin: 0 0 4px 14px;
        height: 2px;
        content: ' ';
        text-shadow: none;
        background-color: #e9ecef;
        width: 25%;
      }
    }
    button {
      margin-top: 20px;
      border-radius: 50%;
      border: 0;
      background: linear-gradient(45deg, #7ac14e73, #0f9c4f70);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      color: #fff;
      &:hover,
      &:focus,
      &:focus-visible {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 9px 12px rgba(0, 0, 0, 0.2);
        background: #009750;
      }
    }
    .redirect-section {
      font-weight: 600;
      h5 {
        font-size: clamp(14px, 1.5vw, 16px);
        font-weight: 600;
        color: #fff;
      }
      a {
        margin: 20px auto;
        border-radius: 5px;
        border: 0;
        background: #009750;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 40px;
        text-decoration: none;
        color: #fff;
        &:hover,
        &:focus,
        &:focus-visible {
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 9px 12px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}
</style>
